import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    document.addEventListener("visibilitychange", () => {
      $.ajax({
        url: "/paypay/complete_payment.json",
        dataType: 'json',
        type: 'GET',
        cache: true,
        success: (data) => {
        },
        error: (XMLHttpRequest, textStatus, errorThrown) =>  {
          $(this.element).modal('show');
        }
      });
    });

    $.ajax({
      url: "/paypay/complete_payment.json",
      dataType: 'json',
      type: 'GET',
      cache: true,
      success: (data) => {
      },
      error: (XMLHttpRequest, textStatus, errorThrown) =>  {
        $(this.element).modal('show');
      }
    });

  }
}
